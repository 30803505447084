import { List, ReferenceField, Show, Edit, FilterButton, useNotify, Create, required } from 'react-admin'
import { useWatch } from "react-hook-form";
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, BooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';

const productFilters = [
    // <TextInput label="Email" source="email" defaultValue="" alwaysOn/>,
    // <SelectInput label="Subject" source="subject" defaultValue="" alwaysOn choices={licenseTypes}/>,
];

export const DistributionWebhookList = () => (
    <List filters={productFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id"/>
            <ReferenceField source="licenseDistributionId" reference="license-distributions" label="License Distribution" />
            <TextField source="endpointUrl"/>
            <NumberField source="licenseBundleCountMax" />
            <BooleanField source="sendEmail" />
        </Datagrid>
    </List>
);

const DistributionWebhookActions = ({ basePath, data, resource }) => (
    <TopToolbar>
    </TopToolbar>
);

export const DistributionWebhookShow = (props) => (
    <Show {...props} actions={<DistributionWebhookActions/>}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <ReferenceField source="licenseDistributionId" reference="license-distributions" label="License Distribution" />
            <TextField source="endpointUrl"/>
            <NumberField source="licenseBundleCountMax" />
            <BooleanField source="sendEmail" />
        </SimpleShowLayout>
    </Show>
);

export const DistributionWebhookCreate = (props) => {
    const transform = data => {
        return data
    };

    return (
        <Create {...props} transform={transform}>
            <SimpleForm>
                <ReferenceInput label="License Distribution" source="licenseDistributionId" reference="license-distributions" perPage={100}>
                    <SelectInput validate={required()} fullWidth/>
                </ReferenceInput>
                <TextInput source="licenseBundleCountMax" defaultValue="1" fullWidth/>
                <BooleanInput source="sendEmail" />
            </SimpleForm>
        </Create>
    );
}
