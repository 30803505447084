import { List, Show, Edit, FilterButton, useNotify, Create, required, ReferenceField } from 'react-admin'
import { useWatch } from "react-hook-form";
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, BooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';

const productFilters = [
    // <TextInput label="Email" source="email" defaultValue="" alwaysOn/>,
    // <SelectInput label="Subject" source="subject" defaultValue="" alwaysOn choices={licenseTypes}/>,
];

export const LicenseDistributionList = () => (
    <List filters={productFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id"/>
            <ReferenceField source="distributorId" reference="distributors" label="Distributor" />
            <TextField source="description" />
            <BooleanField source="consignment"/>
            <NumberField source="msrpUsd" label="MSRP USD" options={{ style: 'currency', currency: 'USD' }}/>

            <ReferenceField source="productId" reference="products" label="Product" />
            <TextField source="subject" />
            <TextField source="durationIso" />
        </Datagrid>
    </List>
);

export const LicenseDistributionCreate = (props) => {
    const transform = data => {
        let duration_iso = 'P';
        if (data.durationYears > 0) { duration_iso += `${data.durationYears}Y` }
        if (data.durationMonths > 0) { duration_iso += `${data.durationMonths}M` }
        if (data.durationDays > 0) { duration_iso += `${data.durationDays}D` }
        delete data.durationYears;
        delete data.durationMonths;
        delete data.durationDays;

        if (duration_iso != 'P') {  // no duration == perpetual
            data.durationIso = duration_iso;
        }
        return data
    };

    return (
        <Create {...props} transform={transform}>
            <SimpleForm>
                <ReferenceInput label="Distributor" source="distributorId" reference="distributors">
                    <SelectInput validate={required()} fullWidth/>
                </ReferenceInput>
                <TextInput source="description" defaultValue="" fullWidth validate={required()}/>

                <ReferenceInput label="Product" source="productId" reference="products">
                    <SelectInput validate={required()} fullWidth/>
                </ReferenceInput>
                <TextInput source="subject" defaultValue="" fullWidth/>
                <div>
                    <NumberInput source="durationYears" defaultValue={0} />
                    <NumberInput source="durationMonths" defaultValue={0} />
                    <NumberInput source="durationDays" defaultValue={0} />
                </div>
                <BooleanInput label="Are these Trial licenses?" source="isTrial" />

                <BooleanInput source="consignment" defaultValue={false}/>
                <NumberInput source="msrpUsd" label="$/license" options={{ style: 'currency', currency: 'USD' }} validate={required()} defaultValue={59}/>
            </SimpleForm>
        </Create>
    );
}
