import { List, Show, ReferenceArrayField, ArrayField, ShowBase, Edit, FilterButton, useNotify, Create, required, useRecordContext, ReferenceField } from 'react-admin'
import { Box, Grid } from '@mui/material';
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, BooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';
import licenseTypes from './licenseTypes';
import DepositCodesToBundle from 'components/DepositCodesToBundle'
import ShowCodesForBundle from 'components/ShowCodesForBundle'
import {PerpetualVersionList, PerpetualVersionChoices} from './perpetualVersions';


const LicenseBundleListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

const licenseBundleFilters = [
];

export const LicenseBundleList = () => (
    <List filters={licenseBundleFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id"/>
            <DateField source="dateCreated" />
            <TextField source="note" />
            <NumberField source="count"/>
        </Datagrid>
    </List>
);

const LicenseBundleActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        {/* <ShowCodesForBundle record={data}/>
        <DepositCodesToBundle record={data}/> */}
    </TopToolbar>
);

export const LicenseBundleShow = (props) => (
    <Show {...props} actions={<LicenseBundleActions/>}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <DateField source="dateCreated" />
            <TextField source="note" />
            <NumberField source="count"/>
            <ReferenceArrayField reference="licenses" source="licenseIds" label="Licenses" >
                <Datagrid rowClick="show" bulkActionButtons={false}>
                    <ReferenceField source="productId" reference="products" />
                    <TextField source="id" />
                    <TextField source="token" />
                </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);

const hasDuration = (subject) => {
    return !['LIFETIME', 'EDULAB', 'MUSIO_ONE', 'MUSIO_ONE_UPGRADE'].includes(subject)
}

const isMusio1 = (subject) => {
    return ['MUSIO_ONE', 'MUSIO_ONE_UPGRADE'].includes(subject)
}

const Musio1VersionInput = (props) => (
    <SelectInput label="Musio 1 Version" choices={PerpetualVersionChoices} defaultValue={PerpetualVersionList.length} {...props}/>
);

export const LicenseBundleCreate = (props) => {
    const transform = data => {
        let duration_iso = 'P';
        if (data.durationYears > 0) { duration_iso += `${data.durationYears}Y` }
        if (data.durationMonths > 0) { duration_iso += `${data.durationMonths}M` }
        if (data.durationDays > 0) { duration_iso += `${data.durationDays}D` }
        delete data.durationYears;
        delete data.durationMonths;
        delete data.durationDays;

        if (duration_iso != 'P') {  // no duration == perpetual
            data.durationIso = duration_iso;
        }
        return data
    };
    return (
        <Create {...props} transform={transform}>
            <SimpleForm>
                <ReferenceInput label="Product" source="productId" reference="products">
                    <SelectInput validate={required()} fullWidth/>
                </ReferenceInput>
                <TextInput source="subject" defaultValue="" fullWidth/>

                <div>
                    <NumberInput source="durationYears" defaultValue={0} />
                    <NumberInput source="durationMonths" defaultValue={0} />
                    <NumberInput source="durationDays" defaultValue={0} />
                </div>
                
                <BooleanInput label="Are these Trial licenses?" source="isTrial" />

                <ReferenceInput label="Distributor" source="distributorId" reference="distributors">
                    <SelectInput validate={required()} fullWidth/>
                </ReferenceInput>
                <TextInput source="description" label="Description (eg: Sept 2023 Promo)" validate={required()} fullWidth/>
                <div>
                    <NumberInput source="count" label="Num Licenses" defaultValue={0} min={0}/>
                    <BooleanInput source="consignment" label="Consignment" defaultValue={false} validate={required()}/>
                    <NumberInput source="price" label="$/license" options={{ style: 'currency', currency: 'USD' }} validate={required()} defaultValue={59}/>
                </div>
            </SimpleForm>
        </Create>
    );
};
