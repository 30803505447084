import { useDataProvider, useRefresh, useRecordContext, List, Show, Edit, FilterButton, useNotify, Create, required, email, ArrayField, ReferenceField, ArrayInput, SimpleFormIterator } from 'react-admin'
import { useWatch } from "react-hook-form";
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, BooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';
import axios from 'axios'





import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const SendBillForPurchaseTransactionButton = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const refresh = useRefresh();
    const handleClick = () => {
        console.log(record);
        let data = {
            
        }
        axios({method: "POST", url: `/api/v1/purchase-transactions/${record.id}/send-bill`})
        .then(resp=>{
            notify(`Sent bill to email: ${record.email}`);
            refresh();
        })
        .catch(err => {
            if (err.response == 400) {
                //TODO: bad request
            }
            else if (err.response.status == 422) {
                notify(err.response.data.error.toString());
            }
        });
    };

    return (
        <Button color="primary" onClick={handleClick}>Send Bill</Button>
    );
};
SendBillForPurchaseTransactionButton.propTypes = {
    record: PropTypes.object,
};


const purchaseTransactionFilters = [
    // <TextInput label="Email" source="email" defaultValue="" alwaysOn/>,
    // <SelectInput label="Subject" source="subject" defaultValue="" alwaysOn choices={licenseTypes}/>,
];

export const PurchaseTransactionList = () => (
    <List filters={purchaseTransactionFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id"/>
            <DateField source="dateCreated" />
            <DateField source="dateUpdated" showTime={true}/>
            <TextField source="status"/>
            <EmailField source="email" />
            {/* <ReferenceField source="userId" reference="users" label="User" /> */}
            <TextField source="transactionId"/>
            <TextField source="discountId"/>
            <ArrayField source="lineItems">
                <Datagrid bulkActionButtons={false}>
                    <ReferenceField label="Price" source="priceId" reference="prices"/>
                    <NumberField source="quantity" />
                </Datagrid>
            </ArrayField>
        </Datagrid>
    </List>
);


const PurchaseTransactionShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <SendBillForPurchaseTransactionButton record={data}/>
    </TopToolbar>
);

export const PurchaseTransactionShow = (props) => {
    return (
        <Show {...props} actions={<PurchaseTransactionShowActions/>}>
            <SimpleShowLayout>

                <TextField source="id"/>
                <DateField source="dateCreated" />
                <DateField source="dateUpdated" showTime={true}/>
                <TextField source="status" />
                <EmailField source="email" />
                {/* <ReferenceField source="userId" reference="users" label="User" /> */}
                <TextField source="transactionId"/>
                <TextField source="discountId"/>
                <ArrayField source="lineItems">
                    <Datagrid bulkActionButtons={false}>
                        <ReferenceField label="Price" source="priceId" reference="prices"/>
                        <NumberField source="quantity" />
                    </Datagrid>
                </ArrayField>

            </SimpleShowLayout>
        </Show>
    )
}



const validateUniqueLineItems = (values) => {
    let price_id_set = new Set();
    for (const i in values) {
        const item = values[i];
        if (price_id_set.has(item.priceId)) {
            return 'priceId must be unique for each line item'
        }
        price_id_set.add(item.priceId);
    }
}

const validateQuantities = async (values) => {
    for (const i in values) {
        const item = values[i];
        let price = await axios.get(`api/v1/prices/${item.priceId}`).then((resp) => { return resp.data; });
        if (item.quantity < price.minQuantity || item.quantity > price.maxQuantity) {
            return `line-item ${i} - quantity must be between ${price.minQuantity} and ${price.maxQuantity}`
        }
    }
}

//validate={validatePurchaseTransactionCreation}
const emailFilterToQuery = email => ({ email: email });
const priceRenderer = price => `${price.name} -- [${price.description}]`;
export const PurchaseTransactionCreate = (props) => {
    return (
        <Create {...props} >
            <SimpleForm sanitizeEmptyValues>
                <TextInput label="Email Address" source="email" validate={[email(), required()]} fullWidth/>

                {/* <ReferenceInput label="User" source="userId" reference="users" helperText="required for subscriptions">
                    <AutocompleteInput
                        optionText="email"
                        suggestionLimit="5"
                        filterToQuery={emailFilterToQuery}
                        fullWidth
                    />
                </ReferenceInput> */}

                <ArrayInput source="lineItems" validate={[validateUniqueLineItems, validateQuantities]}>
                    <SimpleFormIterator inline>
                        <ReferenceInput label="Price" source="priceId" reference="prices" filter={{ isSubscription: false }}>
                            <SelectInput optionText={priceRenderer} validate={required()} fullWidth />
                        </ReferenceInput>
                        <NumberInput source="quantity" default={1} validate={required()} />
                    </SimpleFormIterator>
                </ArrayInput>
                <TextInput source="discountId"/>
                <BooleanInput label="Send Bill?" source="sendBill" />
            </SimpleForm>
        </Create>
    );
}
