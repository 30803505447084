import { List, Show, Edit, FilterButton, useNotify, Create, required } from 'react-admin'
import { useWatch } from "react-hook-form";
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, BooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';

const productFilters = [
    // <TextInput label="Email" source="email" defaultValue="" alwaysOn/>,
    // <SelectInput label="Subject" source="subject" defaultValue="" alwaysOn choices={licenseTypes}/>,
];

export const ProductList = () => (
    <List filters={productFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="sku"/>            
        </Datagrid>
    </List>
);