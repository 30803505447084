import { List, Show, Edit, FilterButton, useNotify, Create, required } from 'react-admin'
import { JsonField, JsonInput } from "react-admin-json-view";
import { useWatch } from "react-hook-form";
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, BooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';

const priceFilters = [
    // <TextInput label="Email" source="email" defaultValue="" alwaysOn/>,
    // <SelectInput label="Subject" source="subject" defaultValue="" alwaysOn choices={licenseTypes}/>,
];

export const PriceList = () => (
    <List filters={priceFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
            <BooleanField source="isSubscription" />
            <TextField source="periodIso" />
            <JsonField
                source="customData"
                jsonString={false} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: false,
                }}
            />
        </Datagrid>
    </List>
);